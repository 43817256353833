.App {
  /*background-color: #333842;*/
  /*background: url("./assets/images/map.png") center center no-repeat;
  background-color: rgba(255,255,255,0.8);
  background-blend-mode: lighten;*/
  background-color: rgb(241, 243, 244);
  /*background-color: radial-gradient(circle, rgb(241, 243, 244) 0%, rgb(0, 99, 133) 100%);*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginForm {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  padding: 50px;
  width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}

.LoginLogo {
  /*background-color: #007bff;*/
  text-align: center;
  /*color: #ffffff;*/
}

.actions {
  width: 1px;
  text-align:right;
  white-space: nowrap;
}

.status {
  width: 1px;
  white-space: nowrap;
}

#map {
  width: 100%;
  height: 400px;
  background-color: grey;
  margin-top: 20px;
  margin-bottom: 20px;
}

#mapFullScreen {
  position: absolute;
  top: 56px;
  left: 0px;
  height: calc(100vh - 72px);
  margin: 0px;
}

.buttonFull {
  width: 34px;
  height: 34px;
  /* line-height: 30px; */
  border: 2px solid rgba(0,0,0,0.3);
  background-color: white;
  border-radius: 4px;
}

hr
{
  width: 100%
}

.hidetext { -webkit-text-security: disc; /* Default */ }

.react-bootstrap-table table { table-layout: auto !important; }

.spinnerStyle {
  margin-right: 10px;
}

.my-flex-end {
  display: flex;
  justify-content: flex-end;
}

.blue {
  color: rgb(0, 99, 133);
}

.cookiesBanner {
  width: 100%;
  background-color: black;
  position: fixed;
  bottom: 0;
  color: white;
  z-index: 1000;
  font-size: small;
  padding: 10px;
}

.filter-label {
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
